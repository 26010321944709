* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --color-primary: #6c63ff;
  --color-success: #00bf8e;
  --color-warning: #f7c94b;
  --color-danger: #f75842;
  --color-danger-variant: rgba(247, 88, 66, 0.4);
  --color-white: #fff;
  --color-black: #000;
  --color-light: rgba(255, 255, 255, 0.8);
  --color-bg: #f5f5f5;
  --color-content1bg: #ffe59d;
  --color-content2bg: #2581b3;
  --color-content3bg: #0f3454;
  --color-main: #89ccff;
  --color-hover: #53b7f4;
  --color-text1: #155175;
  --color-text2: #49e0ed;
  --color-text3: #626262;
  --color-border1: #e6e6e6;
  --color-border2: #acacac;
  --color-border3: #c1c1c1;
  --color-tableLine: rgba(0, 0, 0, 0.1);

  --container-width-large: 70%;
  --container-width-middle: 90%;
  --container-width-small: 94%;

  --transition: all 400ms ease;
}

body {
  font-family: "-apple-system", "BlinkMacSystemFont", "Malgun Gothic", "맑은 고딕", helvetica,
    "Apple SD Gothic Neo", sans-serif;
  line-height: 1.7;
  color: var(--color-black);
  background: var(--color-bg);
  width: 100%;
  height: 100%;
}

header {
  box-shadow: 0px 0px 8px rgb(107 107 107 / 80%);
  background: #fff;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.3rem;
}

a {
  color: var(--color-white);
}

img {
  width: 100%;
  display: block;
  object-fit: cover;
  vertical-align: middle;
}

@font-face {
  font-family: "S-CoreDream-5Medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Recipekorea";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/Recipekorea.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cafe24Dongdong";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Dongdong.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

label {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

button {
  cursor: pointer;
}

.bg-c01 {
  background: #00bed8;
}
.bg-c02 {
  background: #ff7624;
}
.bg-c03 {
  background: #8abd0e;
}
.bg-c04 {
  background: #ff59a3;
}
.bg-c05 {
  background: #5f7ee3;
}
.bg-c06 {
  background: #c95fe3;
}

em,
address {
  font-style: normal;
}

ul,
ol {
  list-style: none;
}
